
.console-notification .v-data-table__mobile-row {
    height: auto !important;
}
.category-content {
    ::v-deep {
        img {
            max-width: 100%;
        }
    }
}
