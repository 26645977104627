
::v-deep {
  .white-space-pre-line {
      white-space: pre-line;
  }
  .v-pagination button {
      box-shadow: none !important;
      border: thin solid rgba(0, 0, 0, 0.12);
  }
  .cursor-pointer {
        cursor: pointer;
    }
}
