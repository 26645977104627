
.item-select-input.error--text {
    &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: -4px;

        width: calc(100% + 8px);
        height: 100%;
        border: 4px solid #ff5252;
        pointer-events: none;
    }

    ::v-deep .v-select__selections > input::placeholder {
        color: #ff5252 !important;
    }
}
